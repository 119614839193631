<template>
  <!-- 下单页面 -->
  <Layout>
    <div class="main-order-fill">
      <div class="base-width">
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
        <div class="c-title-2 s22" v-ani.fade-up>填写并核对订单信息</div>
        <!-- 地址 -->
        <div class="md-address-list c-card" v-ani.fade-up>
          <div class="hd flex-middle flex-between">
            <div class="s18 color-2 font-medium">收货人信息</div>
            <div class="btn-add flex-middle pointer color-blue s16" @click="dialog_address = true">
              <img src="@/assets/images/icon-add-2.png">
              <span>新增地址</span>
            </div>
          </div>
          <div class="bd">
            <div class="list">
              <div class="li flex-middle s16 color-9" v-for="(item, index) in addressArr" :key="item.address_id"
                :class="{ on: item.check }">
                <div class="icon bg-contain" @click="handleChooseAddress(index)"></div>
                <div class="name">{{ item.consignee }}</div>
                <div class="phone">{{ item.mobile }}</div>
                <div class="text">{{ item.address_name }}{{ item.address }}</div>
                <BaseButton class="btn" style-type='light' size='mini' :hover='false' v-if="item.is_default">
                  默认
                </BaseButton>
                <div class="btn btn-2 s14 color-blue" v-else @click="setDefaultClick(item)">设为默认地址</div>
                <div class="btn btn-2 s14 color-blue" @click="editAddress(item)">编辑</div>
                <div class="btn btn-2 s14 color-blue" @click="delAddress(item)">删除</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 订单商品列表 -->
        <OrderProductList foot :goodsArr="baseObj.cartList"></OrderProductList>
        <!-- 备注： -->
        <textarea class="area s16 color-2" placeholder="备注：" v-model="formData.user_note"></textarea>
        <!-- 采购类型 -->
        <div class="md-fill-1 c-card">
          <div class="hd flex-between flex-middle">
            <div class="lf flex-middle">
              <div class="s18 color-2 font-medium name">
                <span>采购类型</span><span class="color-red">*</span>
              </div>
              <BaseRadio class="s16" :options="procureTypeList" v-model="formData.purchase_type" />
            </div>
            <div class="rg flex-middle">
              <!-- v-show="formData.purchase_type == 2" -->
              <div class="btn btn-1 color-green s16 flex-middle pointer" @click="dialog_manage = true">
                <img src="@/assets/images/icon-change.png">
                <span>更换</span>
              </div>
              <div class="btn btn-2 color-blue s16 flex-middle pointer" @click="addAddress">
                <img src="@/assets/images/icon-add-2.png">
                <span>{{ formData.purchase_type == 1 ? '新增收货地址' : '新增项目地址' }}</span>
              </div>
            </div>
          </div>
          <!-- 个人 -->
          <!-- <div class="bd" v-if="formData.purchase_type == 1">
            <div class="s16 color-4 list">
              <div class="col-1">
                <div class="li">
                  <span class="t1">收货人姓名：</span>
                  <span class="t2">李四</span>
                </div>
                <div class="li">
                  <span class="t1">联系人电话：</span>
                  <span class="t2">18012312335</span>
                </div>
              </div>
              <div class="col-2">
                <div class="li">
                  <span class="t1">身份证号码：</span>
                  <span class="t2">1234567891234569878</span>
                </div>
                <div class="li flex-middle">
                  <span class="t1">身份证图片：</span>
                  <div class="t2 flex-middle">
                    <div class="idcard">
                      <img src="@/assets/images/idcard.jpg" alt="">
                    </div>
                    <div class="idcard">
                      <img src="@/assets/images/idcard.jpg" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3"></div>
            </div>
          </div> -->
          <!-- 企业 v-if="formData.purchase_type == 2" -->
          <div class="bd">
            <div class="s16 color-4 list">
              <div class="col-1">
                <div class="li">
                  <span class="t1">业主单位：</span><span class="t2">{{ procureData.owner_unit }}</span>
                </div>
                <div class="li">
                  <span class="t1">总包单位：</span><span class="t2">{{ procureData.winning_unit }}</span>
                </div>
                <div class="li">
                  <span class="t1">分包单位：</span><span class="t2">{{ procureData.shall_unit }}</span>
                </div>
                <div class="li">
                  <span class="t1">收货方：</span><span class="t2">{{ procureData.project_consignee }}</span>
                </div>
              </div>
              <div class="col-2">
                <div class="li">
                  <span class="t1">工程名称：</span><span class="t2">{{ procureData.project_name }}</span>
                </div>
                <div class="li">
                  <span class="t1">收货地址：</span><span class="t2">{{ procureData.project_address }}</span>
                </div>
                <div class="li">
                  <span class="t1">联系人电话：</span><span class="t2">{{ procureData.project_phone }}</span>
                </div>
                <div class="li">
                  <span class="t1">身份证号码：</span><span class="t2">{{ procureData.id_card_no }}</span>
                </div>
              </div>
              <div class="col-3">
                <div class="li">
                  <span class="t1">购买方公司：</span><span class="t2">{{ procureData.company_name }}</span>
                </div>
                <div class="li flex-middle">
                  <span class="t1">营业执照：</span>
                  <div class="t2 flex-middle">
                    <div class="idcard">
                      <img :src="procureData.license_img[0]">
                    </div>
                  </div>
                </div>
                <div class="li flex-middle">
                  <span class="t1">身份证图片：</span>
                  <div class="t2 flex-middle">
                    <div class="idcard" v-for="(item, index) in procureData.id_card_img">
                      <img :src="item">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 是否开具发票 -->
        <div class="md-fill-2 c-card s16">
          <div class="form-item">
            <div class="form-item_label s18 color-2 font-medium">
              <span>是否开具发票</span><span class="color-red">*</span>
            </div>
            <BaseRadio class="s16" :options="invoiceList" v-model="formData.invoice_type" />
          </div>
          <div class="invoice-wrap" v-if="formData.invoice_type == 1" @click="() => dialog_invoice = true">
            <BaseButton v-if="!invoiceData" style-type='light' class="btn-invoice">
              请选择发票
            </BaseButton>
            <div class="invoice s16 color-4" v-else>
              <div class="t1 font-medium">发票类型：{{ invoiceData.type == 1 ? '专业增值税' : '普通增值税' }}</div>
              <div class="list flex-wrap font-light">
                <div class="li">发票抬头：{{ invoiceData.invoice_title }}</div>
                <div class="li">收票人手机：{{ invoiceData.mobile }}</div>
                <div class="li">税号：{{ invoiceData.tax_number }}</div>
                <div class="li">收票人邮箱：{{ invoiceData.email }}</div>
                <div class="li">税点：{{ invoiceData.tax_rate }}</div>
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item_label s18 color-2 font-medium">
              <span>产品检测报告</span><span class="color-red">*</span>
            </div>
            <BaseRadio class="s16" :options="reportList" v-model="formData.check_report" />
          </div>
          <div class="form-item">
            <div class="form-item_label s18 color-2 font-medium">
              <span>配送方式</span><span class="color-red">*</span>
            </div>
            <BaseRadio class="s16" :options="shippingList" v-model="formData.shipping_type" @change="confirmFn()" />
            <div class="time">
              <span class="color-4">期望到达日期</span>
              <BaseDate @change="expectedChange"></BaseDate>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item_label s18 color-2 font-medium">
              <span>支付方式</span><span class="color-red">*</span>
            </div>
            <BaseRadio class="s16" :options="payList" v-model="formData.pay_type" :disabled="!baseObj.enable_period"
              @change="payChange" />
          </div>
          <div class="summarize s16 color-4 text-right">
            <div class="item">采购类型：{{ formData.purchase_type == 1 ? '个人' : '企业' }}</div>
            <div class="item">配送范围：{{ baseObj.distanceInfo?.ways }}</div>
            <div class="item">配送金额：¥{{ baseObj.total_shipping_price }}</div>
            <div class="item">商品总额：¥{{ baseObj.total_price }}</div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="md-fill-3 c-card">
          <div class="row-1 flex-middle flex-right s16 color-4">
            <BaseRadioSimple v-model="agree" @change="() => agree = true">
              阅读并同意
              <!-- <router-link to="/agreement">《采购协议》</router-link> -->
              <span @click="jumpAgreement">《采购协议》</span>
            </BaseRadioSimple>
            <BaseButton tag='a' href='/index.html' download>下载合同</BaseButton>
            <BaseButton style-type='light' @click="copyText">复制链接</BaseButton>
          </div>
          <div class="foot flex-middle">
            <div class="lf s16 color-2 font-medium">已选：{{ baseObj.total_num }}种商品</div>
            <div class="rg flex-middle">
              <div class="price font-medium">
                <span class="s16 color-2">共计：</span>
                <span class="s26 color-red">
                  ¥{{ formData.pay_type == 1 ? baseObj.total_price : baseObj.period_pay_account }}
                </span>
              </div>
              <BaseButton class="font-bold" color="red" size='large' @click="submitOrder">提交订单</BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗-收货人信息 -->
    <DialogAddress v-if="dialog_address" :addressId="addressId" @close="dialog_address = false" @update="addressUpdate" />
    <!-- 采购类型，新增收货地址 -->
    <DialogAddress2 v-if="dialog_address2" @close="dialog_address2 = false" @update="updateAddress2" />
    <!-- 项目地址 -->
    <DialogAddressProject v-if="dialog_address3" @close="dialog_address3 = false" @update="updateAddress3" />
    <!-- 项目地址管理 -->
    <DialogProjectManage v-if="dialog_manage" @close="dialog_manage = false" @change="changeProjectAddress" />
    <!-- 发票管理 -->
    <DialogInvoice v-if="dialog_invoice" :value="invoiceData ? invoiceData.invoice_id : ''"
      @close="dialog_invoice = false" @change="handleGetInvoice" />
  </Layout>
</template>

<script>
import { AddressListApi, CartConfirmOrderApi, CartSubOrderApi, AddressSetDefaultApi, AddressDelApi, PurchaseDefaultApi } from "@/request/api/order.js";
import { ProtocolsApi } from "@/request/api/login";
export default {
  created() {
    this.fromType = this.$route.query.fromType;
    this.formData.type = this.$route.query.fromType;
    this.addressListFn();
    this.defaultProFn();
    // this.confirmFn();
  },
  data() {
    return {
      fromType: 1, // 1=从购物车下单，2=从商品详情
      baseObj: {
        cartList: [],
        address: {},
        distanceInfo: {},
      },
      formData: {
        address_id: "",
        suppliers_id: "",
        purchase_type: 1,  // 1=个人，2=企业
        purchase_id: "",  // 企业id
        invoice_type: 2,   // 1-开发票  2-不需要
        invoice_id: 0,
        shipping_type: 2, // 配送方式
        expected_time: "", // 到货时间
        check_report: 0, // 检测报告
        user_note: "",
        pay_type: 1,
        type: 1,
      },
      routes: [
        { name: '首页', link: '/' },
        { name: '集采商城', link: '/shop' },
        { name: '下单详情页', link: '' }
      ],
      addressArr: [],
      dialog_address: false,
      addressId: 0,
      procureTypeList: [
        { value: 1, name: '个人' },
        { value: 2, name: '企业' },
      ],
      invoiceList: [
        { value: 1, name: '开具发票', tips: '如需开发票，请在全部商品确认收货后的7天内申请开票' },
        { value: 2, name: '不需要' },
      ],
      invoiceData: null, // 发票数据
      shippingList: [
        { value: 1, name: '货拉拉' },
        { value: 2, name: '普通物流' },
      ],
      dialog_address2: false,
      dialog_address3: false,
      dialog_manage: false,
      procureData: {
        license_img: [],
        license_img: [],
      },  // 项目数据
      reportList: [
        { value: 1, name: '需要' },
        { value: 0, name: '不需要' },
      ],
      payList: [
        { value: 1, name: '全款支付' },
        { value: 2, name: '账期支付', text: '实际支付商品总额的30%: <span class="color-red">￥15009.00元</span> <br>剩余70%账期金额，合计35000.00元整，请在30天内完成支付，否则将影响到您的信誉。', showText: true },
      ],
      dialog_invoice: false,
      agree: false
    };
  },
  methods: {
    jumpAgreement() {
      ProtocolsApi().then(({ status, data }) => {
        if (status == 200) {
          window.open(data.caigou, '_blank')
        }
      })
    },
    // 支付方式改变
    payChange(e) {
      if (!baseObj.enable_period) {
        this.$message.error('暂不支持账期支付');
        return
      }
      this.confirmFn();
    },
    // 设置默认地址
    setDefaultClick(item) {
      AddressSetDefaultApi({ address_id: item.address_id }).then(({ status, data }) => {
        if (status == 200) {
          this.$message.success('设置成功');
          this.addressListFn();
        }
      })
    },
    // 删除地址
    delAddress(item) {
      AddressDelApi({ address_id: item.address_id }).then(({ status, data }) => {
        if (status == 200) {
          this.$message.success('删除成功');
          this.addressListFn();
        }
      })
    },
    // 期望到货时间
    expectedChange(str) {
      this.formData.expected_time = str;
    },
    // 地址列表
    addressListFn() {
      AddressListApi({ p: 1, page_num: 100 }).then(({ status, data }) => {
        if (status == 200) {
          data.list.map(item => {
            item.check = false;
          })
          this.addressArr = data.list;
          this.confirmFn();
        }
      })
    },
    // 预确认订单
    confirmFn() {
      CartConfirmOrderApi(this.formData).then(({ status, data }) => {
        if (status == 200) {
          let arr = [];
          data.cartList.map(item => {
            arr.push(item.suppliers_id);
          })
          this.formData.suppliers_id = arr.join(',');
          this.baseObj = data;
          // 选默认地址
          this.addressArr.map(item => {
            if (item.address_id == data.address.address_id) {
              this.formData.address_id = item.address_id;
              item.check = true;
            }
          })
          // 账期提示
          this.payList[1].text = `实际支付商品总额的${data.period_rate}%: <span class="color-red">￥${data.period_pay_account}元</span> <br>剩余${100 - data.period_rate}%账期金额，合计${data.total_price}元整，请在${data.period_day}天内完成支付，否则将影响到您的信誉。`
          this.payList[1].showText = false;
        }
      })
    },
    // 提交订单
    submitOrder() {
      if (!this.agree) {
        this.$message.error('请勾选采购协议');
        return;
      }
      this.formData.invoice_type == 1 ? this.formData.invoice_id = this.formData.invoice_id : this.formData.invoice_id = 0;
      CartSubOrderApi(this.formData).then(({ status, data }) => {
        if (status == 200) {
          this.$message.success('提交订单成功');
          this.$router.push(`/pay?order_id=${data.data}&merge_order_sn=${data.merge_order_sn}`);
        }
      })
    },
    handleChooseAddress(i) {
      this.addressArr.forEach((el, idx) => {
        el.check = idx == i
      })
      this.formData.address_id = this.addressArr[i].address_id;
    },
    addressUpdate() {
      this.addressListFn();
      this.confirmFn();
    },
    editAddress(item) {
      this.addressId = item.address_id;
      this.dialog_address = true;
    },
    addAddress() {
      if (this.procureType == 1) {
        this.dialog_address2 = true
      } else {
        this.dialog_address3 = true
      }
    },

    // 新加收货地址
    updateAddress2() { },
    // 新加项目地址
    updateAddress3() { },
    // 默认项目
    defaultProFn() {
      PurchaseDefaultApi().then(({ status, data }) => {
        if (status == 200) {
          this.procureData = data;
          this.procureData.license_img = [data.license_img];
          this.procureData.id_card_img = data.id_card_img.split(',');
          this.formData.purchase_id = data.id;
          this.confirmFn();
        }
      })
    },
    // 选择项目地址
    changeProjectAddress(e) {
      this.procureData = e;
      this.formData.purchase_id = e.id;
      this.confirmFn();
    },
    // 发票
    handleGetInvoice(e) {
      this.invoiceData = e;
      this.formData.invoice_id = e.invoice_id;
    },
    // 复制
    copyText() {
      let inputDom = document.createElement('textarea') // js创建一个文本框
      document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
      inputDom.value = 'http://yizhu-new.oss-cn-shenzhen.aliyuncs.com/638b0b23b602f.docx' //将需要复制的内容赋值到创建的文本框中
      inputDom.select() //选中文本框中的内容
      inputDom.focus()
      document.execCommand('copy') //执行复制操作
      document.body.removeChild(inputDom) //最后移出
      this.$message.success('复制成功');
    },
  }
};
</script>

<style lang="scss" scoped>
.main-order-fill {
  padding-bottom: .4rem;
}

.c-title-2 {
  margin-top: .32rem;
}

.md-address-list {
  margin-top: .17rem;

  .hd {
    height: .59rem;
    padding: 0 .3rem;
    border-bottom: 1px solid #e6f2f8;
  }

  .btn-add {
    img {
      width: .14rem;
      margin-right: .04rem;
    }
  }

  .list {
    padding: .09rem 0;

    .li {
      padding: 0 .3rem;
      min-height: .3rem;
      margin: .13rem 0;

      &.on {
        color: #444;

        .icon {
          background-image: url(~@/assets/images/icon-101.png);
        }
      }

      &:hover {
        .btn-2 {
          visibility: visible
        }
      }
    }

    .icon {
      width: .16rem;
      height: .16rem;
      margin-right: .1rem;
      background-image: url(~@/assets/images/icon-102.png);
      cursor: pointer;
    }

    .name {
      margin-right: .15rem;
    }

    .phone {
      margin-right: .15rem;
    }

    .text {
      margin-right: .3rem;
    }

    .base-button {
      padding: 0 .11rem;
    }

    .btn {
      margin-right: 0.15rem;
      cursor: pointer;

      &-2 {
        visibility: hidden;
      }
    }
  }
}

.md-order-product {
  margin-top: .39rem;
}

.area {
  display: block;
  width: 100%;
  height: 1.2rem;
  background-color: #ffffff;
  border-radius: 0.04rem;
  border: solid 0.01rem #cae1ec;
  padding: .2rem .3rem;
  margin-top: .4rem;

  &::placeholder {
    color: #999;
  }
}

.md-fill-1 {
  margin-top: .39rem;

  .hd {
    height: .58rem;
    border-bottom: 1px solid #e6f2f8;
    padding: 0 .3rem;

    .name {
      margin-right: .3rem;
    }

    .rg {
      .btn-1 {
        img {
          width: .14rem;
          margin-right: .05rem;
        }
      }

      .btn-2 {
        margin-left: .19rem;

        img {
          width: .14rem;
          margin-right: .04rem;
        }
      }
    }
  }

  .list {
    display: flex;
    padding: .17rem .3rem;

    .col-1 {
      width: 3.5rem;
    }

    .col-2 {
      width: 5.6rem;
    }

    .col-3 {
      flex: 1;

      .li {
        .t1 {
          min-width: .96rem;
        }
      }
    }

    .li {
      min-height: .4rem;
      display: flex;
      align-items: center;
    }

    .idcard {
      width: .4rem;
      height: .4rem;
      border-radius: 0.04rem;
      border: solid 0.01rem #cae1ec;
      padding: .03rem;
      font-size: 0;
      margin: .09rem .1rem .09rem 0;

      img {
        width: .32rem;
        height: .32rem;
        object-fit: contain;
      }
    }
  }
}

.md-fill-2 {
  margin-top: .39rem;
  padding: .09rem .3rem .29rem;

  .form-item {
    height: .58rem;
    display: flex;
    align-items: center;

    &_label {
      padding-right: .3rem;
      min-width: 1.46rem;
    }
  }

  .invoice-wrap {
    .btn-invoice {
      min-width: 1.2rem;
    }

    .invoice {
      width: 6.24rem;
      border: solid 0.01rem #e6f2f8;
      padding: .28rem 0.3rem .22rem;
      margin-top: .07rem;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: .5rem;
        top: -.08rem;
        transform: rotate(-45deg);
        width: .15rem;
        height: .15rem;
        border-top: solid 0.01rem #e6f2f8;
        border-right: solid 0.01rem #e6f2f8;
        background: #fff;
      }

      .list {
        margin-top: .1rem;
        line-height: calc(30 / 16 * 1em);

        .li {
          &:nth-of-type(2n+1) {
            width: 2.3rem;
          }

          &:nth-of-type(2n) {
            width: calc(100% - 2.3rem);
          }
        }
      }
    }
  }

  .time {
    margin-left: .3rem;

    .base-date {
      margin-left: .1rem;
    }
  }

  .summarize {
    line-height: calc(36 / 16 * 1em);

    .item {
      margin-bottom: .04rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.md-fill-3 {
  margin-top: .4rem;

  .row-1 {
    padding: .3rem .1rem;
    border-bottom: 1px solid #e6f2f8;

    .base-button {
      min-width: 1rem;
      margin-right: .2rem;
    }

    .c-radio {
      a {
        color: $blue;
      }
    }
  }

  .foot {
    justify-content: space-between;
    padding: .3rem;

    .rg {
      .price {
        margin-right: .38rem;
      }

      .base-button {
        background-color: #e01624;
        min-width: 1.4rem;

        &:hover {
          opacity: .9;
        }
      }
    }
  }
}
</style>